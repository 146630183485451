import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Container } from "react-bootstrap";
import { Seo } from "../components/Seo";
import { useLocation } from "@reach/router"

export default function ({ data:{privacy:{bannerTitle, privacyContent:json}} }) {
    const cnt =json;
    const options={
        renderNode:{

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="privacy-image" alt="update img" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),
        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="privacy-paragraph">{children}</p>),
        },
        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            [MARKS.BOLD]: text => <b>{`${text}`} </b>,
        },
    }
    
    const pathLocation = useLocation();
    const pageUrl = pathLocation.href;


    return <Layout>
        <Seo 
            title="Privacy - Sogody" 
            description="Sogody’s Privacy and Data Security policies ensure that the data received by the clients with the purpose of provision of services, and the data collected by the client’s customers in the scope of service provision, remain under the sole control of the client and completely safe."
            url={pageUrl}
        />
        <div className="privacy-banner">
            <Container> 
                <p className="banner-title">{bannerTitle}</p>
            </Container>
        </div>
        <div className="privacy-cnt"> 
        <Container className="up-container"> 
            <div className="privacy-content">
                {documentToReactComponents(cnt.json, options)
                    && (
                        <p>{documentToReactComponents(cnt.json, options)}</p>
                    )
                }
            </div>
        </Container>
        </div>
    </Layout>
}

export const query = graphql`
query GetPrivacy{
    privacy: contentfulPrivacyAndDataSecurityPolicies {
        bannerTitle
        privacyContent {
        json
        }
    }
}
`


